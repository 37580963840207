import React, { FC } from "react";
import HeadingBanner from "../../components/TopHeading";
import { MiddleSection } from "../../components/CommonStyles";
import LeftTabs from "../../components/Tabs/LeftTabs";
import { Container } from "react-bootstrap";
import PropertyMaintenance from "./PropertyMaintenance";
import SnowIceManagement from "./SnowIceManagement";
import HardscapeConstruction from "./HardscapeConstruction";
import SoftscapeConstruction from "./SoftscapeConstruction";
import TreeServices from "./TreeServices";
import IrrigationServices from "./IrrigationServices";
import FertilizationWeedControlServices from "./FertilizationWeedControlServices";
import WasteManagementServices from "./WasteManagementServices";
import ContactSection from "../../components/ContactUs";

const tablist = [
  {
    title: "Property Maintenance",
    eventKey: "service1",
    tabcontent: <PropertyMaintenance />,
  },
  {
    title: "Snow & Ice Management",
    eventKey: "service2",
    tabcontent: <SnowIceManagement />,
  },
  {
    title: "Hardscape Construction",
    eventKey: "service3",
    tabcontent: <HardscapeConstruction />,
  },
  {
    title: "Softscape Construction",
    eventKey: "service4",
    tabcontent: <SoftscapeConstruction />,
  },
  {
    title: "Tree Services",
    eventKey: "service5",
    tabcontent: <TreeServices />,
  },
  // {
  //   title: "Fence & Deck Services",
  //   eventKey: "service6",
  //   tabcontent: <FenceDeckServices />,
  // },
  {
    title: "Irrigation Services",
    eventKey: "service7",
    tabcontent: <IrrigationServices />,
  },
  {
    title: "Fertilization & Weed Control Services",
    eventKey: "service8",
    tabcontent: <FertilizationWeedControlServices />,
  },
  {
    title: "Waste Management Services",
    eventKey: "service9",
    tabcontent: <WasteManagementServices />,
  },
];

const index: FC = () => {
  return (
    <>
      <HeadingBanner heading="Pristine Services" />
      <MiddleSection>
        <Container>
        <LeftTabs taboptions={tablist} />
        </Container>
      </MiddleSection>
      <Container>
      <ContactSection />
      </Container>
    </>
  );
};

export default index;
