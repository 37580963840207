import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MiddleSection, OuterBox } from "../../components/CommonStyles";
import { SmallPara } from "../../components/Typography";
import HeadingBanner from "../../components/TopHeading";
import ContactSection from "../../components/ContactUs";
import { useNavigate } from "react-router-dom";
import { ProjectImage } from "./style";
import image1 from "../../assets/img/projects/Fence/1.jpg";
import image2 from "../../assets/img/projects/Fence/2.jpg";
import image3 from "../../assets/img/projects/Fence/3.jpg";

const Fence: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeadingBanner
        heading="Fence"
        onClick={() => {
          navigate(-1);
        }}
        linkHeading
      />
      <MiddleSection>
        <Container>
          <OuterBox>
            <SmallPara
              mb={16}
              text="
        Here at Pristine we specialize in custom decks and fencing. We make sure the customer is satisfied with
        all specs before we start the build to ensure customer satisfaction. 
        "
              color="#424242"
            />
            <SmallPara
              mb={24}
              text="
        Our decks and fencing department
        also offers other builds such as: pergolas, gazebos, pool houses, sheds and many other wooden
        structures. Pristine uses quality materials including wood, brick and stone to design and install secure
        structures.
        "
              color="#424242"
            />

            <Row className="gy-4 justify-content-center">
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image1})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image2})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image3})` }}
                />
              </Col>
            </Row>
          </OuterBox>
          <ContactSection />
        </Container>
      </MiddleSection>
    </>
  );
};

export default Fence;
