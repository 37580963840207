import React, { FC, useRef, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { H3 } from "../Typography";

const TabsStyle = styled.div`
  .nav-pills .nav-link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #183b56;
    padding: 8px;
    margin: 8px 0;
    @media screen and (max-width: 600px) {
      text-align: center;
    }

    &.active {
      background: #f4fbff;
      border-radius: 4px;
    }
  }
  .flex-column {
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    max-width: 263px;
    min-width: 263px;
    margin-right: 32px;
    @media screen and (max-width: 600px) {
      max-width: 100%;
      min-width: 100%;
      margin-bottom: 30px;
    }
  }
  .tab-content {
    padding: 3rem;
    padding-top: 0 !important;
    background: #fff;
    border-radius: 8px;
    width: 100%;
    @media screen and (max-width: 992px) {
      padding: 1.5rem;
    }
  }
  .heading {
    font-size: 26px;
    padding-top: 3rem;
  }
  .d-flex {
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
`;

interface TabProps {
  taboptions: Array<any>;
}

const LeftTabs: FC<TabProps> = ({ taboptions }) => {
  const [location] = useSearchParams();
  const [key, setKey] = useState(location.get("tab") || "service1");
  const ref = useRef<any>()

  return (
    <TabsStyle>
      <Tab.Container
        id="services"
        activeKey={key}
        onSelect={(k: any) => {
          setKey(k)
          ref.current.scrollIntoView()
        }}
      >
        <div className="d-flex">
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              {taboptions.map(({ title, eventKey }, index) => {
                return (
                  <Nav.Link eventKey={eventKey} key={index}>
                    {title}
                  </Nav.Link>
                );
              })}
            </Nav.Item>
          </Nav>
          <Tab.Content ref={ref} id="tab-content-services">
            {taboptions.map(({ title, tabcontent, eventKey }, index) => {
              const text = title;
              return (
                <Tab.Pane eventKey={eventKey} key={index}>
                  <H3 text={text} mb={24} className="heading" />
                  {tabcontent}
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </div>
      </Tab.Container>
    </TabsStyle>
  );
};

export default LeftTabs;
