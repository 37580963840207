import { Container, ListGroup, Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import footerLogo from "../../assets/img/footer-logo.png";
import getCurrentYear from "../../utils";
import { MenuList } from "../../utils/helper";
import { SmallHeading } from "../Typography";
import { FooterBox } from "./style";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentpath = location.pathname;
  const handleMenuSelect = (e: any, link: string) => {
    e.preventDefault();
    navigate(link);
  };

  const year = getCurrentYear();

  return (
    <FooterBox className="footer py-4">
      <Container>
        <div className="d-flex align-items-center">
          <div className="logo">
            <img src={footerLogo} alt="logo" />
          </div>
          <div className="ms-auto">
            <ListGroup horizontal className="menulist" activeKey={currentpath}>
              {MenuList.map((menu) => {
                return (
                  <ListGroup.Item key={menu.key}>
                    <Nav.Link
                      href={menu.link}
                      onClick={(e) => handleMenuSelect(e, menu.link)}
                    >
                      {menu.title}
                    </Nav.Link>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
            <SmallHeading
              text={` © ${year} Pristine. All right reserved.`}
              className="copyright"
            />
          </div>
        </div>
      </Container>
    </FooterBox>
  );
};

export default Footer;
