import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  ImageClass,
  MiddleSection,
  OuterBox,
} from "../../components/CommonStyles";
import { SmallPara } from "../../components/Typography";
import image1 from "../../assets/img/projects/Stone-Front-Steps/1.jpg";
import image2 from "../../assets/img/projects/Stone-Front-Steps/2.jpg";
import image3 from "../../assets/img/projects/Stone-Front-Steps/3.jpg";
import HeadingBanner from "../../components/TopHeading";
import ContactSection from "../../components/ContactUs";
import { useNavigate } from "react-router-dom";
import { ProjectImage } from "./style";

const StoneFrontSteps: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeadingBanner
        heading="Stone Front Steps"
        onClick={() => {
          navigate(-1);
        }}
        linkHeading
      />
      <MiddleSection>
        <Container>
          <OuterBox>
            
            <Row className="gy-4">
              <Col xs="12" md="4">
                <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image1})` }}
                />
              </Col>
              <Col xs="12" md="4">
                <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image2})` }}
                />
              </Col>
              <Col xs="12" md="4">
                <ProjectImage
                  className="normalimg"
                  style={{ backgroundImage: `url(${image3})` }}
                />
              </Col>
             
            </Row>
          </OuterBox>
          <ContactSection />
        </Container>
      </MiddleSection>
    </>
  );
};

export default StoneFrontSteps;
