import { Navbar } from "react-bootstrap";
import styled from "styled-components";

export const NavbarStyle = styled(Navbar)`

  &.transparent {
    @media screen and (min-width: 992px) {
      background-color: transparent !important;
    }
  }
  &.bg-primary {
    .nav-link {
      color: #fff;
    }
  }
  padding: 0;
  .navbar-brand {
    padding: 0;
    img {
      @media screen and (max-width: 600px) {
        width: 55px !important;
      }
    }
  }
  .nav-link {
    padding: 0 0 0 50px !important;
    font-family: "Hiragino Mincho Pro";
    font-weight: 300;
    color: #08426e;
    &.active {
      font-weight: 600;
    }
    @media screen and (max-width: 1199px) {
      padding: 0 0 0 30px !important;
    }
    @media screen and (max-width: 992px) {
      padding: 10px !important;
    }
  }
`;
