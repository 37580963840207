import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import SelectAutocomplete from "../../components/AutoComplete";
import PrimaryButton from "../../components/Button";
import {
  InputText,
  InputTextStyle,
} from "../../components/FormFields/InputText";
import { H4 } from "../../components/Typography";
import { parseError } from "../../utils";
import { CREATE_QOUTE_API_URL } from "../../utils/constants";
import { Locations } from "../../utils/helper";
import { requestformschema } from "../../utils/schema";

type FormData = {
  full_name: string;
  phone_number: string;
  email: string;
  address: string;
  description: string;
  service_type: string;
  location: string;
  size: number;
  approx_budget?: number;
};

const options = [
  {
    id: 0,
    name: "Landscape Design and Build",
  },
  {
    id: 1,
    name: "Routine Lawn Maintenance",
  },
  {
    id: 2,
    name: "Landscape Enhancement",
  },
  {
    id: 3,
    name: "Aeration or De-thatching",
  },
  {
    id: 4,
    name: "Bin Rental",
  },
  {
    id: 5,
    name: "Snow Management",
  },
  {
    id: 6,
    name: "Irrigation",
  },
  {
    id: 7,
    name: "Tree Services",
  },

  {
    id: 8,
    name: "Fertiliaztion and Weed Control",
  },
];

const RequestForm = () => {
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(requestformschema),
    mode: "onBlur",
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const onSubmit = handleSubmit(async (data) => {
    try {
      data.location = Locations?.[data.location] || "";
      await axios.post(CREATE_QOUTE_API_URL, data);
      reset();
      setShow(true);
    } catch (error: any) {
      toast(parseError(error.response.data.error));
    }
  });

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col xs="12" lg="4">
            <InputText
              label="Full Name"
              id="full_name"
              placeholder="Enter Name"
              error={errors.full_name?.message}
              {...register("full_name")}
            />
          </Col>
          <Col xs="12" lg="4">
            <InputText
              label="Your Email"
              type="email"
              id="email"
              placeholder="Enter Your Email"
              error={errors.email?.message}
              {...register("email")}
            />
          </Col>
          <Col xs="12" lg="4">
            <InputText
              label="Phone Number"
              type="number"
              id="phone_number"
              placeholder="Enter Phone Number"
              error={errors.phone_number?.message}
              {...register("phone_number")}
            />
          </Col>
          <Col xs="12" className="mb-3">
            <InputText
              label="Address"
              id="address"
              as="textarea"
              rows={1}
              placeholder="Enter Address"
              error={errors.address?.message}
              {...register("address")}
            />
          </Col>
          <hr></hr>
          <H4 text="Your Project" color="#183B56" mb={30} />
          <Col xs="12" md="6">
            <Controller
              control={control}
              name="service_type"
              render={({ field: { onChange, value } }) => (
                <SelectAutocomplete
                  items={options}
                  label="Service Type"
                  id="service_type"
                  name="service_type"
                  placeholder="Enter Service Type"
                  value={value}
                  error={errors.service_type?.message}
                  onChange={(value: string) => onChange(value)}
                />
              )}
            />
          </Col>
          <Col xs="12" md="6">
            <InputText
              label="Size (Sq. Ft.)"
              type="number"
              id="size"
              error={errors.size?.message}
              {...register("size")}
            />
          </Col>
          <Col xs="12" md="6">
            <InputText
              label="Approx. Budget (CAD)"
              type="text"
              id="approx_budget"
              error={errors.approx_budget?.message}
              {...register("approx_budget")}
            />
          </Col>
          <Col xs="12" md="6">
            {/* <InputText
              label="Location"
              id="location"
              placeholder="Enter Location"
              error={errors.location?.message}
              {...register("location")}
            /> */}
            <InputTextStyle>
              <Form.Group className="form-group">
                <Form.Label>Location</Form.Label>
                <Form.Select
                  {...register("location")}
                  size="lg"
                  defaultValue=""
                  className="form-control"
                >
                  <option value="" disabled>
                    Select Location
                  </option>
                  <option value="1">Toronto GTA</option>
                  <option value="2">Kawarthas – Supply Depot</option>
                  <option value="3">Eastern Ontario Division</option>
                  <option value="4">Near North Division</option>
                  <option value="5">Capital Division</option>
                </Form.Select>
                <Form.Text className="error-text">
                  {errors.location?.message}
                </Form.Text>
              </Form.Group>
            </InputTextStyle>
          </Col>
          <Col xs="12">
            <InputText
              label="Description"
              id="description"
              as="textarea"
              rows={3}
              placeholder="Enter Description"
              error={errors.description?.message}
              {...register("description")}
            />
          </Col>
          <Col xs="12">
            <PrimaryButton text="Submit" color="primary" type="submit" />
          </Col>
        </Row>
      </Form>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center p-5">
          <H4 text="Thank you for submitting your response." mb={30} />
          <PrimaryButton
            text="Ok"
            color="primary"
            onClick={handleClose}
            round
            variant="outlined"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequestForm;
