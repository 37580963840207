import React, { FC, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavbarStyle } from "./style";
import logo from "../../assets/img/Pristine-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuList } from "../../utils/helper";

const Header: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentpath = location.pathname;
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const handleMenuSelect = (e: any, link: string) => {
    e.preventDefault();
    navigate(link);
  };

  return (
    <>
      <NavbarStyle
        collapseOnSelect
        expand="lg"
        bg={
          currentpath === "/"
            ? colorChange
              ? "primary"
              : "primary transparent"
            : ""
        }
        variant={currentpath === "/" ? "dark" : "light"}
        fixed={currentpath === "/" ? "top" : ""}
      >
        <Container>
          <Navbar.Brand onClick={() => navigate("/")}>
            <img src={logo} alt="logo" style={{width: colorChange ? '80px' : '110px'}} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" activeKey={currentpath}>
              {MenuList.map((menu) => {
                return (
                  <Nav.Link
                    href={menu.link}
                    onClick={(e) => handleMenuSelect(e, menu.link)}
                    key={menu.key}
                  >
                    {menu.title}
                  </Nav.Link>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </NavbarStyle>
     
    </>
  );
};

export default Header;
