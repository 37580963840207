import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MiddleSection, OuterBox } from "../../components/CommonStyles";
import { SmallPara } from "../../components/Typography";
import { ProjectImage } from "./style";
import image1 from "../../assets/img/projects/deck/1.jpg";
import image2 from "../../assets/img/projects/deck/2.jpg";
import image3 from "../../assets/img/projects/deck/3.jpg";
import image4 from "../../assets/img/projects/deck/4.jpg";
import image5 from "../../assets/img/projects/deck/5.jpg";
import image6 from "../../assets/img/projects/deck/6.png";
import image7 from "../../assets/img/projects/deck/7.png";
import image8 from "../../assets/img/projects/deck/8.png";
import HeadingBanner from "../../components/TopHeading";
import ContactSection from "../../components/ContactUs";
import { useNavigate } from "react-router-dom";

const Deck: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeadingBanner
        heading="Decks"
        onClick={() => {
          navigate(-1);
        }}
        linkHeading
      />
      <MiddleSection>
        <Container>
          <OuterBox>
            <SmallPara
              mb={24}
              text="Here at Pristine we specialize in custom decks and fencing. We make sure the customer is satisfied with all specs before we start the build to ensure customer satisfaction."
            />
            <SmallPara
              mb={24}
              text="Our decks and fencing department also offers other builds such as: pergolas, gazebos, pool houses, sheds and many other wooden structures. Pristine uses quality materials including wood, brick and stone to design and install secure structures."
            />
            <Row>
            
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image2})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image3})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image4})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image5})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image1})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image6})` }}
                />
              </Col>
              <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg mb-24"
                  style={{ backgroundImage: `url(${image7})` }}
                />
                </Col>
                <Col xs="12" md="6">
                <ProjectImage
                  className="shortImg"
                  style={{ backgroundImage: `url(${image8})` }}
                />
              </Col>
            </Row>
          </OuterBox>
          <ContactSection />
        </Container>
      </MiddleSection>
    </>
  );
};

export default Deck;
