
import styled from "styled-components";

export const FooterBox = styled.footer`
  padding: 48px 0;
  border-top: 1px solid #e5eaf4;

  .copyright {
    color: rgba(0, 0, 0, 0.3);
    text-align: right;
    margin-top: 10px;
  }
  .list-group-horizontal {
    @media screen and (max-width: 992px) {
      flex-flow: wrap;
    }
  }
 .nav-link{
  &.active {
    font-weight: 600;
  }
 }
  .list-group-item {
    list-style: none;
    float: left;
    padding: 0;
    padding-left: 24px;
    border: none;
    @media screen and (max-width: 992px) {
      padding: 7px 0 7px 24px;
    }
    @media screen and (max-width: 600px) {
      width: 50%;
    }
  }
`;