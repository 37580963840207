import styled from "styled-components";
import image from "../../assets/img/top-bg-img.png";

export const BannerSection = styled.div`
  background: linear-gradient(121.82deg, #08426e -0.38%, #008cf7 138.98%);
  border-radius: 0 0 200px 0;
  position: relative;
  padding-top: 15%;
  height: 100vh;
  z-index: 1;
  @media screen and (max-width: 992px) {
    border-radius: 0 0 100px 0;
    padding: 130px 0 100px;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    padding: 300px 0 30px;
    text-align: center;
  }
  .mastheadimg {
    height: 600px;
    width: 480px;
    background-image: url(${image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0px;
    bottom: 50px;
    @media screen and (max-width: 992px) {
      height: 400px;
      width: 300px;
      top: 55%;
      transform: translate(0, -50%);
    }

    @media screen and (max-width: 600px) {
      height: 200px;
      width: 170px;
      left: 50%;
      top: 80px;
      transform: translate(-50%, 0);
    }
  }

  .gap-4 {
    @media screen and (max-width: 600px) {
      justify-content: center;
      gap: 0.5rem !important;
    }
  }
`;
