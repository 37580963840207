import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import PrimaryButton from "../Button";
import { OuterBox } from "../CommonStyles";
import { InputText, InputTextStyle } from "../FormFields/InputText";
import { H3, H4 } from "../Typography";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactformschema } from "../../utils/schema";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { parseError } from "../../utils";
import { CONTACT_API_URL } from "../../utils/constants";
import { Locations } from "../../utils/helper";

type FormData = {
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  availability_date: string;
  start_time: string;
  end_time: string;
  service_type: string;
  location: string;
};

export const ContactForm = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(contactformschema),
    mode: "onBlur",
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const onSubmit = handleSubmit(async (data) => {
    try {
      data.availability_date = moment(data.availability_date).format(
        "YYYY-MM-DD"
      );
      data.location = Locations?.[data.location] || '';
      await axios.post(CONTACT_API_URL, data);
      reset();
      setShow(true);
    } catch (error: any) {
      toast(parseError(error.response.data.error));
    }
  });

  return (
    <>
      <OuterBox>
        <H3 text="Schedule Discussion" color="#183B56" mb={30} />
        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs="12" md="6">
              <InputText
                label="First Name"
                id="first_name"
                placeholder="Enter First Name"
                error={errors.first_name?.message}
                {...register("first_name")}
              />
            </Col>
            <Col xs="12" md="6">
              <InputText
                label="Last Name"
                id="last_name"
                placeholder="Enter Last Name"
                error={errors.last_name?.message}
                {...register("last_name")}
              />
            </Col>
            <Col xs="12">
              <InputText
                label="Your Email"
                type="email"
                id="email"
                placeholder="Enter Your Email"
                error={errors.email?.message}
                {...register("email")}
              />
            </Col>
            <Col xs="12">
              <InputText
                label="Address"
                as="textarea"
                id="address"
                placeholder="Enter Address"
                error={errors.address?.message}
                rows={1}
                {...register("address")}
              />
            </Col>
            <Col xs="12" md="6">
              <InputText
                label="Availability"
                type="date"
                id="availability_date"
                error={errors.availability_date?.message}
                {...register("availability_date")}
              />
              
            </Col>
            <Col xs="6" md="3">
              <InputText
                label=""
                type="time"
                id="start_time"
                error={errors.start_time?.message}
                {...register("start_time")}
              />
            </Col>
            <Col xs="6" md="3">
              <InputText
                label=""
                type="time"
                id="end_time"
                error={errors.end_time?.message}
                {...register("end_time")}
              />
            </Col>
            <Col xs="12">
              <InputText
                label="Service Type"
                id="service_type"
                placeholder="Enter Service Type"
                error={errors.service_type?.message}
                {...register("service_type")}
              />
            </Col>
            <Col xs="12">
              {/* <InputText
                label="Location"
                id="location"
                placeholder="Enter Location"
                error={errors.location?.message}
                {...register("location")}
              /> */}
              <InputTextStyle>
              <Form.Group className="form-group">
              <Form.Label>Location</Form.Label>
              <Form.Select {...register("location")}  defaultValue="" size="lg" className="form-control">
              <option value="" disabled>Select Location</option>
              <option value="1">Toronto GTA</option>
              <option value="2">Kawarthas – Supply Depot</option>
              <option value="3">Eastern Ontario Division</option>
              <option value="4">Near North Division</option>
              <option value="5">Capital Division</option>
              <option value="6">Brighton – Supply Depot</option>
              </Form.Select>
              <Form.Text className="error-text">{errors.location?.message}</Form.Text>
              </Form.Group>
              </InputTextStyle>
         
            </Col>
            <Col xs="12">
              <PrimaryButton
                type="submit"
                text="Book a Schedule"
                color="primary"
              />
            </Col>
          </Row>
        </Form>
      </OuterBox>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center p-5">
          <H4 text="Thank you for submitting your response." mb={30} />
          <PrimaryButton
            text="Ok"
            color="primary"
            onClick={handleClose}
            round
            variant="outlined"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
