import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../Button";
import { Para } from "../Typography";
import { PageInfo } from "./style";

const Info = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <PageInfo>
        <Row className="justify-content-center align-items-center">
          <Col xs="12" lg="7">
            <Para
              color="#5A7184"
              text="PPM is a full service provider to the Outdoor Property Services industry. Starting in the East Scarborough area of Toronto in 2005 with 1 pickup truck and 2 people, we have now grown into a company of over 160 professionals throughout Ontario, with over 100 vehicles on the road. We strive to exceed client expectations in all that we do, which includes Property Maintenance, Landscape Construction, Tree Services, Enhancements, Irrigation, Water Services, Fertilization and Weed Control, and Waste Management, as well as full service Snow Management and Ice Control. While we started mainly in the condominium industry, we now take care of all types of commercial, industrial, and residential properties."
            />
          </Col>
          <Col xs="12" lg="2" className="text-center">
            <PrimaryButton
              text="Get a Quote"
              onClick={() => navigate("/request-qoute")}
              color="primary"
              round
              variant="outlined"
            />
          </Col>
        </Row>
      </PageInfo>
    </Container>
  );
};

export default Info;
